import { template as template_47fec6d5a77e4179bf16282704cf01ee } from "@ember/template-compiler";
const FKText = template_47fec6d5a77e4179bf16282704cf01ee(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
