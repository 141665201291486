import { template as template_41eae68b21394f81beca7f3b5b7536ad } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_41eae68b21394f81beca7f3b5b7536ad(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
