import { template as template_c9c412fa9f364fb4b1e722a96aebf40e } from "@ember/template-compiler";
const WelcomeHeader = template_c9c412fa9f364fb4b1e722a96aebf40e(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
